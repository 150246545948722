import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "thanks", "name", "email"]
  connect() {
    var Airtable = require('airtable');
    Airtable.configure({
      endpointUrl: 'https://api.airtable.com',
      apiKey: `${this.apiKey()}`
    });
    this.base = Airtable.base(`${this.base()}`);
  }

  subscribe() {
    this.base(`${this.tableName()}`).create([
      {
        "fields": { "name": this.nameTarget.value, "email": this.emailTarget.value }
      },
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log("You've been subscribed to newsletter!");
      });
    });
    this.formTarget.classList.add('is-hidden')
    this.thanksTarget.classList.remove('is-hidden')
  }

  apiKey() {
    return this.getRequest("/airtableapikey")
  }

  tableName() {
    return this.getRequest("/airtabletablename")
  }

  base() {
    return this.getRequest("/airtablebase")
  }

  getRequest(url) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", url, false );
    xmlHttp.send( null );
    return xmlHttp.responseText;
  }
}
